import React from 'react';
import styled from 'styled-components';

import PageBase from '../components/PageBase';
import Container from '../components/Container';

export default function TermsAndConditions() {
	return (
		<PageBase title="Terms & Conditions">
			<StyledTocPage>
				<header className="toc-header">
					<h3>Terms & Conditions</h3>
					<p>
						Please read these terms and conditions carefully. By accessing this website, you agree to be bound by the terms and conditions
						below.
					</p>
					<span>V2.0 / Effective 1st April 2019</span>
				</header>
				<section className="toc">
					<Container>
						<div className="toc-content-header">
							<span>The use of Us, Our or We throughout these terms and conditions refer to:</span>
							<p>
								NEO Financial Solutions Pty Ltd, ABN &nbsp;
								{/* eslint-disable-next-line */}
								<a href="#" className="abn-num">
									64 141 607 098
								</a>{' '}
								/ AFSL 385 845 Registered Office: Level 5, 432 Murray Street, Perth WA 6000
							</p>
						</div>
						<div className="toc-content">
							<div className="toc-content-item">
								<h3>Copyright</h3>
								<p>
									Copyright in the information contained in this site subsists under the Copyright Act 1968 (Cth) and, through international
									treaties, the laws of many other countries. It is owned by us unless otherwise stated. All rights reserved. You may
									download a single copy of information or publications found in this site and, where necessary for use as a reference, make
									a single hard copy. Except as permitted under the Copyright Act 1968 (Cth) or other applicable laws, no part of this
									website, or publications within this site, may be otherwise reproduced, adapted, performed in public or transmitted in any
									form by any process (graphic, electronic or mechanical, including photocopying, recording, taping or by storage in an
									information retrieval system) without our specific written consent.{' '}
								</p>
							</div>
							<div className="toc-content-item">
								<h3>General Advice Warning</h3>
								<p>
									The advice contained on this site does not take into account any persons particular objectives, needs or financial
									situation. Before making a decision regarding the acquisition or disposal of a Financial Product, persons should assess
									whether the advice is appropriate to their objectives, needs or financial situation. Persons may wish to make this
									assessment themselves or seek the help of an adviser. No responsibility is taken for persons acting on the information on
									this site. Persons doing so, do so at their own risk. Before acquiring a financial product, a person should obtain a
									Product Disclosure Statement (PDS) relating to that product and consider the contents of the PDS before making a decision
									about whether to acquire the product.
								</p>
							</div>
							<div className="toc-content-item">
								<h3>Calculators & Assumptions</h3>
								<p>
									The advice contained on this site does not take into account any persons particular objectives, needs or financial
									situation. Before making a decision regarding the acquisition or disposal of a Financial Product, persons should assess
									whether the advice is appropriate to their objectives, needs or financial situation. Persons may wish to make this
									assessment themselves or seek the help of an adviser. No responsibility is taken for persons acting on the information on
									this site. Persons doing so, do so at their own risk. Before acquiring a financial product, a person should obtain a
									Product Disclosure Statement (PDS) relating to that product and consider the contents of the PDS before making a decision
									about whether to acquire the product.
								</p>
							</div>
							<div className="toc-content-item">
								<h3>Links to Third Party Sites</h3>
								<p>
									Our website may contain links to external websites. Whilst such links are provided for your convenience, you should be
									aware that the information handling practices of the linked websites might not be the same as ours and does not constitute
									endorsement of material at those sites or any associated organisation product or service. These external information
									sources are outside our control. It is the responsibility of users to make their own decisions about the accuracy,
									currency, reliability and correctness of the information at those sites. By accessing information at or through this site,
									each user waives and releases us to the full extent permitted by law from any and all claims relating to the usage of the
									material made available through the website. In no event shall we be liable for any incident or consequential damages
									resulting from use of the material. Each website will have its own privacy policy and security standards and you should
									check these as we cannot confirm what standards these third-party websites may adopt.
								</p>
							</div>
							<div className="toc-content-item">
								<h3>Terms of Use/Governing Law</h3>
								<p>
									The information in this site has been prepared in accordance with Australian law for the supply of goods and services.
									This notice and the information in this site, and all matters relating to either, are governed by and are to be construed
									according to the laws applicable in the State of Western Australia and the Commonwealth of Australia ("Australian law").
									The information may not satisfy the laws of any other country. It is not directed at people in any other country and
									should not be relied on by people in any country other than Australia. The information on this site is current at the date
									of publication but may be subject to change.
								</p>
							</div>
							<div className="toc-content-item">
								<h3>Limitation of liability</h3>
								<p>
									The information in this site has been prepared in accordance with Australian law for the supply of goods and services.
									This notice and the information in this site, and all matters relating to either, are governed by and are to be construed
									according to the laws applicable in the State of Western Australia and the Commonwealth of Australia ("Australian law").
									The information may not satisfy the laws of any other country. It is not directed at people in any other country and
									should not be relied on by people in any country other than Australia. The information on this site is current at the date
									of publication but may be subject to change.
								</p>
							</div>
							<div className="toc-content-item">
								<h3>Provision of services within Australia</h3>
								<p>
									This site is designed for Australian residents only. Nothing on this website is an offer or a solicitation of an offer to
									acquire any products or services, by any person or entity outside of Australia.
								</p>
							</div>
						</div>
					</Container>
				</section>
			</StyledTocPage>
		</PageBase>
	);
}

const StyledTocPage = styled.div`
	padding-bottom: 100px;
	& .toc-header {
		padding: 50px 0 20px 0;
		background-color: #192851;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		h3 {
			color: white;
			font-size: 38px;
			font-weight: 700;
			line-height: 71px;
		}

		p {
			color: white;
			font-size: 15px;
			line-height: 25px;
			max-width: 50%;
			display: block;
			margin: 15px auto 0 auto;
		}

		span {
			color: #67839f;
			font-size: 15px;
			line-height: 25px;
			margin-top: 15px;
		}
	}

	& .toc {
		& .container {
			width: 90%;
			flex-direction: column;

			@media screen and (min-width: 768px) {
				width: 60% !important;
			}
		}
		padding: 25px 0;
		& .toc-content-header {
			margin-top: 40px;

			& .abn-num {
				pointer-events: none;
				color: #49667b;
				font-size: 15px;
				line-height: 25px;
				text-decoration: none;
			}
			span {
				color: #192851;
				font-size: 15px;
				font-weight: 700;
				line-height: 25px;
			}
			p {
				color: #49667b;
				font-size: 15px;
				line-height: 25px;
				margin-top: 15px;
			}
		}
		& .toc-content-item {
			margin-top: 50px;

			h3 {
				color: #192851;
				font-weight: 800;
				font-size: 24px;
				line-height: 71px;
			}

			p {
				color: #49667b;
				font-size: 15px;
				line-height: 25px;
			}
		}
	}
`;
